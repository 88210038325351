import React from "react";

export const HeroButton = ({
    icon,
    text,
    onClick = null,
    href = null,
    reverse = false,
}) => {
    const styles = {
        border: "none",
        borderRadius: 0,
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.25)",
        background: "#d9ba5d",
        color: "white",
        fontSize: "1.5rem",
        display: "inline-flex",
        flexDirection: reverse ? "row-reverse" : "row",
        padding: 0,
        textDecoration: "none",
        width: "278px",
        fontFamily: "Open Sans, sans-serif",
        cursor: "pointer",
    };

    const innerTree = (
        <>
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#515b4a",
                    width: "68px",
                    height: "68px",
                    boxShadow: `${
                        reverse ? "-" : ""
                    }3px 0 4px rgba(0, 0, 0, 0.25)`,
                }}
            >
                {icon}
            </span>
            <span
                style={{
                    width: "210px",
                    height: "68px",
                    lineHeight: "68px",
                }}
            >
                {text}
            </span>
        </>
    );

    return href ? (
        <a href={href} style={styles}>
            {innerTree}
        </a>
    ) : (
        <button onClick={onClick} style={styles}>
            {innerTree}
        </button>
    );
};
