import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/Header";
import { PageSection } from "../components/PageSection";
import { ServiceLink } from "../components/ServiceLink";
import { Drawer } from "../components/Drawer";
import { ContactDetails } from "../components/ContactDetails";

const IndexPage = () => {
    const {
        site,
        liner,
        lineup,
        bio,
        youtube,
        deezer,
        amazon,
        apple,
        google,
    } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    keywords
                }
            }

            liner: file(
                sourceInstanceName: { eq: "content" }
                name: { eq: "liner" }
            ) {
                childMarkdownRemark {
                    html
                }
            }

            lineup: file(
                sourceInstanceName: { eq: "content" }
                name: { eq: "lineup" }
            ) {
                childMarkdownRemark {
                    html
                }
            }

            bio: file(
                sourceInstanceName: { eq: "content" }
                name: { eq: "bio" }
            ) {
                childMarkdownRemark {
                    html
                }
            }

            youtube: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "youtube" }
            ) {
                childImageSharp {
                    fixed(height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            apple: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "apple-music" }
            ) {
                childImageSharp {
                    fixed(height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            deezer: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "deezer" }
            ) {
                childImageSharp {
                    fixed(height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            amazon: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "amazon-music" }
            ) {
                childImageSharp {
                    fixed(height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            google: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "google-play-music" }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const serviceLinks = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <ServiceLink
                link="https://www.youtube.com/playlist?list=OLAK5uy_mS3RZZETPIXSyO_vXzsqX_liy66S1-Gr4"
                image={youtube.childImageSharp.fixed}
            />
            <ServiceLink
                link="https://music.apple.com/nl/album/e-e-2/1506100368"
                image={apple.childImageSharp.fixed}
            />
            <ServiceLink
                link="https://music.amazon.com/albums/B086QXBCQ2?do=play"
                image={amazon.childImageSharp.fixed}
            />
            <ServiceLink
                link="http://www.deezer.com/album/139922772"
                image={deezer.childImageSharp.fixed}
            />
            <ServiceLink
                link="https://play.google.com/store/music/album/Eddy_the_Ethiopians_E_E_2?id=Bkbllxr7pzuwqjw5vwr7ji5h2da"
                image={google.childImageSharp.fixed}
            />
        </div>
    );

    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <>
            <Drawer
                open={drawerOpen}
                onOpenStatusRequested={status => setDrawerOpen(status)}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content={site.siteMetadata.description}
                />
                <meta name="author" content={site.siteMetadata.author} />
                <meta name="keywords" content={site.siteMetadata.keywords} />
                <title>{site.siteMetadata.title}</title>
                <html lang="en" />
            </Helmet>
            <Header setDrawerOpen={setDrawerOpen} />
            <PageSection
                title="Liner-notes"
                content={liner.childMarkdownRemark.html}
            />
            <PageSection
                title="Listen to the music"
                content={serviceLinks}
                invertedColor={true}
            />
            <PageSection
                title="Line-up"
                content={lineup.childMarkdownRemark.html}
            />
            <PageSection title="Bio" content={bio.childMarkdownRemark.html} />
            <PageSection
                title="Contact"
                content={<ContactDetails />}
                invertedColor={true}
            />
            <footer
                style={{
                    background: "#515b4a",
                    overflow: "auto",
                    color: "white",
                }}
            >
                <p
                    style={{
                        maxWidth: "600px",
                        padding: "15px 20px",
                        margin: "0 auto",
                    }}
                >
                    &copy; 2020 Edward Capel. Website door{" "}
                    <a
                        href="https://maartenflippo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "white" }}
                    >
                        Maarten Flippo
                    </a>
                </p>
            </footer>
        </>
    );
};

export default IndexPage;
