import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import drawerStyles from "./drawer.module.css";
import { HeroButton } from "./HeroButton";
import { useStaticQuery, graphql } from "gatsby";
import { scrollThresholdListener } from "../utils/scroll";
import { PageSection } from "./PageSection";
import { ContactDetails } from "./ContactDetails";
import { ServiceLink } from "./ServiceLink";

export const Drawer = ({ open, onOpenStatusRequested }) => {
    const backdropClassname = classNames(drawerStyles.backdrop, {
        [drawerStyles.backdropOpen]: open,
    });
    const drawerClassname = classNames(drawerStyles.drawer, {
        [drawerStyles.drawerOpen]: open,
    });

    const { cdIcon } = useStaticQuery(graphql`
        query {
            cdIcon: file(relativePath: { eq: "icons/disc.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        return scrollThresholdListener(
            300,
            () => setShowButton(false),
            () => setShowButton(true)
        );
    });

    const contactContent = (
        <>
            <p>
                To buy a physical copy of <b>E &amp; E II</b>, contact Edward
                Capel through the details below.
            </p>
            <ContactDetails inverted={true} />
        </>
    );

    const otherMaterialsContent = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <ServiceLink
                text="Live @ Wilhelmina"
                link="https://bluntaxe.bandcamp.com/album/eddy-the-ethiopians-live"
            />
            <ServiceLink
                text="Studiorecordings"
                link="https://bluntaxe.bandcamp.com/album/eddy-the-ethiopians"
            />
        </div>
    );

    return (
        <>
            <div
                className={classNames(drawerStyles.drawerToggle, {
                    [drawerStyles.drawerToggleShown]: showButton,
                })}
            >
                <HeroButton
                    reverse={true}
                    icon={
                        <Img
                            fixed={cdIcon.childImageSharp.fixed}
                            alt="CD icon"
                        />
                    }
                    text="Buy the CD!"
                    onClick={() => onOpenStatusRequested(!open)}
                />
            </div>
            <div
                className={backdropClassname}
                onClick={() => onOpenStatusRequested(false)}
            ></div>
            <aside className={drawerClassname}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        paddingTop: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <button
                        style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                        }}
                        onClick={() => onOpenStatusRequested(false)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x-circle"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                    </button>
                </div>
                <PageSection title="Get the CD!" content={contactContent} />
                <PageSection
                    title="Other material"
                    content={otherMaterialsContent}
                />
            </aside>
        </>
    );
};
