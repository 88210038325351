import React from "react";
import Img from "gatsby-image";
import serviceLinkStyles from "./servicelink.module.css";

export const ServiceLink = ({ image, text, link }) => (
    <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={serviceLinkStyles.serviceLink}
    >
        {image && (
            <Img
                fixed={image}
                alt="Service link logo"
                className={serviceLinkStyles.serviceLinkContent}
            />
        )}
        {text && (
            <span className={serviceLinkStyles.serviceLinkContent}>{text}</span>
        )}
    </a>
);
