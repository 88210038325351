// The debounce function receives our function as a parameter
const debounce = fn => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
            cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
            // Call our function and pass any params we received
            fn(...params);
        });
    };
};

/**
 * Setup listeners for the page's scroll position.
 *
 * @param {number} threshold
 * @param {Function} lessThanThresholdFn
 * @param {Function} greaterThanThresholdFn
 * @return {Function} Cleans up the event listeners.
 */
export const scrollThresholdListener = (
    threshold,
    lessThanThresholdFn,
    greaterThanThresholdFn
) => {
    let lastScroll = 0;

    const listener = debounce(() => {
        const currentScroll = window.scrollY;
        if (lastScroll === currentScroll) return;

        if (currentScroll < threshold) lessThanThresholdFn();
        else greaterThanThresholdFn();

        lastScroll = currentScroll;
    });

    document.addEventListener("scroll", listener, { passive: true });

    return () => {
        document.removeEventListener("scroll", listener);
    };
};
