import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { HeroButton } from "./HeroButton";
import headerStyles from "./header.module.css";

const Backdrop = () => (
    <svg
        viewBox="0 0 100 200"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        height="200px"
        width="100%"
    >
        <filter id="dropshadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset dy="2" result="offsetblur" />
            <feComponentTransfer>
                <feFuncA type="linear" slope="0.5" />
            </feComponentTransfer>
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>
        <polygon
            points="0,0 100,0 100,190 0,150"
            fill="#d9ba5d"
            style={{ filter: "url(#dropshadow)" }}
        />
        <polygon
            points="0,0 100,0 100,70 0,100"
            fill="#515b4a"
            style={{ filter: "url(#dropshadow)" }}
        />
    </svg>
);

const Header = ({ setDrawerOpen }) => {
    const { albumArt, spotifyIcon, cdIcon } = useStaticQuery(graphql`
        query {
            albumArt: file(relativePath: { eq: "album-cover.jpeg" }) {
                childImageSharp {
                    fixed(width: 250, height: 250) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            spotifyIcon: file(relativePath: { eq: "icons/spotify.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            cdIcon: file(relativePath: { eq: "icons/disc.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <header>
            <Backdrop />
            <div
                style={{
                    textAlign: "center",
                }}
            >
                <Img
                    fixed={albumArt.childImageSharp.fixed}
                    alt="E&E II album cover"
                    style={{
                        border: "5px solid white",
                        margin: "-150px auto 0 auto",
                        display: "block",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    }}
                />
                <h1
                    style={{
                        fontFamily: "Nova Flat",
                        fontSize: "2rem",
                        padding: "20px",
                        textAlign: "center",
                    }}
                >
                    Eddy &amp; The Ethiopians
                </h1>
                <div className={headerStyles.ctaButtons}>
                    <HeroButton
                        icon={
                            <Img
                                fixed={spotifyIcon.childImageSharp.fixed}
                                alt="Spotify icon"
                            />
                        }
                        text="Listen now!"
                        href="https://open.spotify.com/album/04LKHspeRhKIgb36VYKne4?si=hzxczdBDRw2h0ul2Ch2fKw"
                    />
                    <div className={headerStyles.ctaDivider}>
                        <span>or</span>
                    </div>
                    <HeroButton
                        reverse={true}
                        icon={
                            <Img
                                fixed={cdIcon.childImageSharp.fixed}
                                alt="CD icon"
                            />
                        }
                        text="Buy the CD!"
                        onClick={() => setDrawerOpen(true)}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
