import React from "react";

const ContactItem = ({ icon, link, label, inverted }) => (
    <p style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
                marginLeft: "1rem",
                color: inverted ? "black" : "white",
            }}
        >
            {label}
        </a>
    </p>
);

const TelIcon = ({ inverted }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill={inverted ? "#d9ba5d" : "white"}
    >
        <path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z" />
    </svg>
);

const MailIcon = ({ inverted }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        fill={inverted ? "#d9ba5d" : "white"}
    >
        <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z" />
    </svg>
);

const FacebookIcon = ({ inverted }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke={inverted ? "#d9ba5d" : "white"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
    </svg>
);

export const ContactDetails = ({ inverted = false }) => (
    <div>
        <ContactItem
            label="+31 6 21261976"
            link="tel:+31621261976"
            icon={<TelIcon inverted={inverted} />}
            inverted={inverted}
        />
        <ContactItem
            label="e.capel@planet.nl"
            link="mailto:e.capel@planet.nl"
            icon={<MailIcon inverted={inverted} />}
            inverted={inverted}
        />
        <ContactItem
            label="Eddy and the Ethiopians on Facebook"
            link="https://www.facebook.com/EddyandtheEthiopians/"
            icon={<FacebookIcon inverted={inverted} />}
            inverted={inverted}
        />
    </div>
);
