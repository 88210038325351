import React from "react";
import pageSectionStyles from "./pagesection.module.css";

export const PageSection = ({ title, content, invertedColor = false }) => (
    <section
        className={
            invertedColor
                ? pageSectionStyles.invertedPageSection
                : pageSectionStyles.pageSection
        }
    >
        <div className={pageSectionStyles.container}>
            <h2 className={pageSectionStyles.pageSectionTitle}>{title}</h2>
            {typeof content === "string" && (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
            {typeof content !== "string" && content}
        </div>
    </section>
);
